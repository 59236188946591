import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'take'
})
export class TakePipe implements PipeTransform {
    public transform(value: any, count: number): Array<any> {
        if (!value) {
            return [];
        }
        
        if (!Array.isArray(value)) {
            throw new Error('Source must be of type array!');
        }

        if (!Number.isInteger(count)) {
            throw new Error('Count option must be of type number!');
        }

        const array = value as Array<any>;
        return array.slice(0, count);
    }
}
