import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'default'
})
export class DefaultPipe implements PipeTransform {
    transform(value: string | null, defaultValue: string): string {
        if (!defaultValue) {
            defaultValue = '';
        }

        return value ?? defaultValue;
    }
}
