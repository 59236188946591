import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotPipe } from './not.pipe';
import { SafePipe } from './safe.pipe';
import { TakePipe } from './take.pipe';
import { MarkedPipe } from './marked.pipe';
import { SplitPipe } from './split.pipe';
import { SkipEmptyPipe } from './skip-empty.pipe';
import { DefaultPipe } from './default.pipe';
import { StripSaltPipe } from './strip-salt.pipe';
import { AnyPipe } from './any.pipe';
import { VarDirective } from './var.directive';
import { LoadedPipe } from './loaded.pipe';

@NgModule({
    declarations: [
        NotPipe,
        SafePipe,
        TakePipe,
        MarkedPipe,
        SplitPipe,
        SkipEmptyPipe,
        DefaultPipe,
        StripSaltPipe,
        AnyPipe,
        VarDirective,
        LoadedPipe
    ],
    imports: [CommonModule],
    exports: [
        NotPipe,
        SafePipe,
        TakePipe,
        MarkedPipe,
        SplitPipe,
        SkipEmptyPipe,
        DefaultPipe,
        StripSaltPipe,
        AnyPipe,
        VarDirective,
        LoadedPipe
    ]
})
export class FrameworkModule {}
