import { Pipe, PipeTransform } from '@angular/core';
import { INVALID_OPERATION } from '../app-errors';
import { Username } from '../auth/username';

@Pipe({
    name: 'stripSalt'
})
export class StripSaltPipe implements PipeTransform {
    public transform(value: any): string {
        if (!value) {
            return value;
        }

        if (typeof value === 'string' || value instanceof String) {
            return Username.removeSalt(value as string);
        }

        throw new Error(INVALID_OPERATION);
    }
}
