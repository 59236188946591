import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked } from 'marked';

@Pipe({
    name: 'marked'
})
export class MarkedPipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) {
        let renderer = new marked.Renderer();
        renderer.link = (href: string, title: string, text: string): string => {
            return `<a target="_blank" href="${href}" title="${title}">${text}</a>`;
        };

        marked.setOptions({
            breaks: true,
            renderer: renderer
        });
    }

    transform(value: any): SafeHtml {
        if (value && value.length > 0) {
            const markdown = marked(value);
            return markdown;
        }
        return value;
    }
}
