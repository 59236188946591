import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'skipEmpty'
})
export class SkipEmptyPipe implements PipeTransform {
    public transform(value: Array<any>): Array<any> {
        if (!value) {
            return [];
        }

        if (!Array.isArray(value)) {
            throw new Error('Source must be of type array!');
        }

        const array = value as Array<any>;
        return array.filter(x => x !== null && x != '0');
    }
}
