import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'any'
})
export class AnyPipe implements PipeTransform {
    transform(value: any[] | null): boolean {
        if (!value) {
            return false;
        }

        return value?.length > 0;
    }
}
